import { Button,ButtonBase,Dialog, DialogContent, Menu, MenuItem, TextareaAutosize } from '@material-ui/core'
import * as React from 'react'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import * as styles from './styles'
import { Insight } from 'graphql/types'
import { ButtonSpinner, Textarea } from '@chakra-ui/react'
import { useLazyQuery, useMutation } from '@apollo/client'
import mutateInsight from 'graphql/mutations/mutateInsight'
import adminInsightResult from 'graphql/queries/adminInsightResult'

 type Props = {
    InsightDetails: any
    refetch: any
 }

const InsightDetails = ({InsightDetails, refetch}: Props) => {
    
    const uniqueBills = new Map()
    let billNumber = 1
    InsightDetails.insights.forEach((v) => {
        if (!uniqueBills.has(v.BillHash)) {
            uniqueBills.set(v.BillHash, billNumber++)
        }
    })
    const [updateInsight, updateInsightResult]  = useMutation(mutateInsight)
    const getInsightResult = useLazyQuery(adminInsightResult)
    const [anchorEl, setAnchorEl] = React.useState<any>({});
    const open = (InsightID) => Boolean(anchorEl[InsightID]);
    const handleClick = (InsightID: string, event: React.MouseEvent<HTMLButtonElement>) => {
        const el = {...anchorEl }
        el[InsightID] = event.currentTarget
        setAnchorEl(el);
    };

    const handleClose = (ins: Insight, Status: string) => {
        console.log(ins)
        updateInsight({
            variables: {
                UserID: ins.UserID,
                InsightID: ins.InsightID,
                Action: "status",
                Data: Status
            }
        })
        refetch()
        const el = {...anchorEl }
        el[ins.InsightID] = null
        setAnchorEl(el);
    };
    const getBill = async (insight: Insight) => {
        await getInsightResult[0]({
            variables: {
                UserID: insight.UserID,
                InsightID: "Bill:"+insight.BillId
            }
        })
    };
    const addNotes = async (insight: Insight, notes: string) => {
        await updateInsight({
            variables: {
                UserID: insight.UserID,
                InsightID: insight.InsightID,
                Action: "notes",
                Data: notes
            }
        })
        refetch()
    }
    React.useEffect(() => {
        if (getInsightResult[1].data) downloadPDF(getInsightResult[1].data.adminInsightResult)
    }, [getInsightResult[1].data])

    const downloadPDF = (pdf) => {
        console.log(pdf)
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "acaciaBillDownload.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    return (
        <div style={{margin: "13px 8px"}}>
            <div>
                <div className={styles.row}>
                    { InsightDetails.insights.map((insight: Insight) => {
                        const thisInsight = insight

                        return (
                            <>
                                <div key={thisInsight.InsightID} className={styles.subrow}>

                                    <ButtonBase className={styles.header}  onClick={() => getBill(thisInsight)}>
                                        Bill #{uniqueBills.get(thisInsight.BillHash)}
                                    </ButtonBase>
                                    <ButtonBase className={styles.header} onClick={(e) => handleClick(thisInsight.InsightID, e)}>
                                        {thisInsight.Status === "" ? thisInsight.Error === "" ? "OK" : "Error" : thisInsight.Status}
                                    </ButtonBase>
                                    <Menu
                                        id={thisInsight.InsightID}
                                        anchorEl={anchorEl[thisInsight.InsightID]}
                                        open={open(thisInsight.InsightID)}
                                        MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={() => handleClose(thisInsight, "OK")}>OK</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "Ignore")}>Ignore</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "Error")}>Error</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "Escalate")}>Escalate</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "Fixed")}>Fixed</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "FixedSendInsight")}>FixedSendInsight</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "FixedReset")}>FixedReset</MenuItem>
                                    </Menu>
                                    <div className={styles.info}>
                                        <div>
                                            <strong>{new Date(insight.GeneratedAt).toLocaleString()}</strong>
                                            <br />
                                            {insight.Summary?.ProviderName}
                                            {insight.Error}
                                        </div>
                                    </div>

                                    <div className={styles.info}>
                                        {insight.Notes.split("\n").map((v) => {
                                            return <p key={v}>{v}</p>
                                        })}
                                    </div>
                                    <Textarea placeholder='Type new notes into this textarea and then blur (click anywhere or press tab) to save' className={styles.textInput} onBlur={(e) => { void addNotes(thisInsight, e.target.value)}} />
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default InsightDetails
